<template>
  <div>
    <b>{{ item.display_name }}</b>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true },
  },
};
</script>
