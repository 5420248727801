<template>
  <div v-if="editJobType">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updateJobType)">
        <section class="mb-1">
          <label>Name</label>
          <validation-provider
            v-slot="validationContext"
            :rules="{ required: true }"
            name="Name"
          >
            <b-form-input
              v-model="editJobType.name"
              :state="getValidationState(validationContext)"
              placeholder="Enter session type name..."
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </section>

        <section class="mb-1">
          <label>Display Name</label>
          <validation-provider
            v-slot="validationContext"
            :rules="{ required: true }"
            name="Display Name"
          >
            <b-form-input
              v-model="editJobType.display_name"
              :state="getValidationState(validationContext)"
              placeholder="Enter session type display name..."
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </section>

        <section class="mb-1">
          <label>Global</label>
          <b-form-checkbox
            v-model="editJobType.global"
            name="check-button"
            switch
          />
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close')">Cancel</b-button>
          <b-button type="submit" variant="primary">Update Session Type</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "UpdateScribeJobTypeModal",
  props: {
    jobType: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editJobType: null,
    };
  },
  mounted() {
    this.editJobType = {
      name: this.$props.jobType.name,
      display_name: this.$props.jobType.display_name,
      global: this.$props.jobType.global,
      enterprise_uids: this.$props.jobType.enterprises.map(e => e.uid),
      summary_type_ids: this.$props.jobType.summary_types.map(s => s.id),
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    updateJobType() {
      this.$emit("update", this.editJobType);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
