<template>
  <paginated-search-select
    :selected="selectedSummaryType"
    :get-label="getLabel"
    :options="summaryTypes"
    :template="template"
    placeholder="Search summary types..."
    @change="updateSelectedSummaryType"
    @change-search="updateSummaryTypeFilter"
  />
</template>

<script>
import AdminPurpleScribeService from "@/services/AdminPurpleScribeService"
import PaginatedSearchSelect from "@/components/input/PaginatedSearchSelect/PaginatedSearchSelect.vue";
import ItemDisplayNameTemplate from "@/components/input/CAutoCompleteTemplates/ItemDisplayNameTemplate.vue";

export default {
  name: "ScribeSummaryTypeSearchSelect",
  components: { PaginatedSearchSelect },
  props: {
    selected: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      loading: true,
      selectedSummaryType: this.$props.selected,
      summaryTypes: [],
      summaryTypeFilters: {
        page: 0,
        page_size: 15,
        search: "",
      },
      template: ItemDisplayNameTemplate,
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(newVal) {
        if(newVal) {
          this.$emit('loading');
        } else {
          this.$emit('loaded');
        }
      }
    },
    selected: {
      deep: true,
      handler() {
        this.selectedSummaryType = this.$props.selected;
      }
    },
  },
  mounted() {
    this.getSummaryTypes();
  },
  methods: {
    getMoreSummaryTypes(page) {
      this.summaryTypeFilters.page = page - 1;
      this.getSummaryTypes();
    },
    async getSummaryTypes() {
      this.loading = true;
      try {
        const res = await AdminPurpleScribeService.listSummaryTypes(this.summaryTypeFilters);
        this.summaryTypes = res.data.data;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get summary types, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    getLabel(summaryType) {
      if (!summaryType) return "";
      return summaryType.display_name;
    },
    updateSelectedSummaryType(summaryType) {
      this.$emit("change", summaryType);
    },
    updateSummaryTypeFilter(search) {
      this.summaryTypeFilters.search = search;
      this.getMoreSummaryTypes(1);
    },
  },
};
</script>

<style scoped lang="scss"></style>
