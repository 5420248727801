<template>
  <div class="network-page">
    <section>
      <div v-if="jobType" class="row">
        <div class="col-12">
          <b-card title="Purple Scribe - Session Details">
            This page contains details on your Purple Scribe session type.
          </b-card>
        </div>

        <div class="col-9">
          <b-card title="Details">
            <div class="row">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td class="pr-1">Name</td>
                    <td>{{ jobType.name }}</td>
                  </tr>
                  <tr>
                    <td class="pr-1">Display Name</td>
                    <td>{{ jobType.display_name }}</td>
                  </tr>
                  <tr>
                    <td class="pr-1">Global</td>
                    <td>{{ jobType.global ? "Yes" : "No" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>
        </div>

        <div class="col-3">
          <b-card title="Actions">
            <b-button
              class="full-width mb-1"
              variant="primary"
              @click="$bvModal.show('modal-update-scribe-job-type')"
            >
              Update
            </b-button>
            <b-button
              class="full-width mb-1"
              variant="danger"
              @click="showDeleteModal"
            >
              Delete
            </b-button>
          </b-card>
        </div>

        <div class="col-12">
          <b-card>
            <div class="d-flex align-items-center">
              <b-card-title class="flex-grow-1">Summary Types</b-card-title>
              <b-button
                class="mb-1"
                variant="primary"
                @click="$bvModal.show('modal-add-summary-type')"
              >
                Add Summary Type
              </b-button>
            </div>
            <b-table
              :fields="summaryTypeHeaders"
              :items="jobType.summary_types"
              class="data-table"
              hover
            >
              <template #cell(global)="data">
                {{ data.item.global ? "Yes" : "No" }}
              </template>
              <template #cell(view)="data">
                <router-link
                  :to="{
                    name: 'admin-purple-scribe-summary-type-view',
                    params: { id: data.item.id },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  View summary type
                </router-link>
              </template>
              <template #cell(remove)="data">
                <div
                  class="text-danger font-weight-bold d-block text-nowrap"
                  @click="showRemoveSummaryTypeModal(data.item.id)"
                >
                  Remove
                </div>
              </template>
            </b-table>

            <b-row>
              <p
                v-if="jobType.summary_types.length === 0"
                class="col-6 ml-2 my-1"
              >
                No records
              </p>
            </b-row>
          </b-card>
        </div>

        <div class="col-12">
          <b-card>
            <section v-if="!jobType.global">
              <div class="d-flex align-items-center">
                <b-card-title class="flex-grow-1">Enterprises</b-card-title>
                <b-button
                  class="mb-1"
                  variant="primary"
                  @click="$bvModal.show('modal-add-enterprise')"
                >
                  Add Enterprise
                </b-button>
              </div>
              <b-table
                :fields="enterpriseHeaders"
                :items="jobType.enterprises"
                class="data-table"
                hover
              >
                <template #cell(view)="data">
                  <router-link
                    :to="{
                      name: 'enterprise-view',
                      params: { uid: data.item.uid },
                    }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    View enterprise
                  </router-link>
                </template>
                <template #cell(remove)="data">
                  <div
                    class="text-danger font-weight-bold d-block text-nowrap"
                    @click="showRemoveEnterpriseModal(data.item.uid)"
                  >
                    Remove
                  </div>
                </template>
              </b-table>

              <b-row>
                <p
                  v-if="jobType.enterprises.length === 0"
                  class="col-6 ml-2 my-1"
                >
                  No records
                </p>
              </b-row>
            </section>

            <section v-else>
              <b-card-title class="flex-grow-1">Enterprises</b-card-title>
              <b-card-text>
                This session type is available to all enterprises.
              </b-card-text>
            </section>
          </b-card>
        </div>
      </div>

      <b-modal
        id="modal-update-scribe-job-type"
        hide-footer
        size="md"
        title="Update Session Type"
      >
        <update-scribe-job-type-modal
          :job-type="jobType"
          @update="updateJobType"
          @close="closeModals"
        />
      </b-modal>

      <b-modal
        id="modal-add-summary-type"
        title="Add Summary Type"
        hide-footer
        size="md"
      >
        <add-summary-type-modal
          @update="addSummaryType"
          @close="closeModals"
        />
      </b-modal>

      <b-modal
        id="modal-add-enterprise"
        title="Add Enterprise"
        hide-footer
        size="md"
      >
        <add-enterprise-modal
          @update="addEnterprise"
          @close="closeModals"
        />
      </b-modal>
    </section>
  </div>
</template>

<script>
import AdminPurpleScribeService from "@/services/AdminPurpleScribeService";
import AddEnterpriseModal from "@/views/adminPurpleScribe/sections/AddEnterpriseModal.vue";
import AddSummaryTypeModal from "@/views/adminPurpleScribe/sections/AddSummaryTypeModal.vue";
import UpdateScribeJobTypeModal from "@/views/adminPurpleScribe/sections/UpdateScribeJobTypeModal.vue";

export default {
  name: "ViewAdminPurpleScribeJobType",
  components: {
    AddEnterpriseModal,
    AddSummaryTypeModal,
    UpdateScribeJobTypeModal,
  },
  data() {
    return {
      jobType: null,
      loading: false,
      summaryTypeHeaders: [
        {
          key: "display_name",
          label: "Display Name",
          class: "col-4",
        },
        {
          key: "name",
          label: "Name",
          class: "col-4",
        },
        {
          key: "global",
          label: "Global",
        },
        {
          key: "view",
          label: "",
        },
        {
          key: "remove",
          label: "",
        },
      ],
      enterpriseHeaders: [
        {
          key: "display_name",
          label: "Display Name",
          class: "col-4",
        },
        {
          key: "name",
          label: "Name",
          class: "col-4",
        },
        {
          key: "uid",
          label: "UID",
        },
        {
          key: "view",
          label: "",
        },
        {
          key: "remove",
          label: "",
        },
      ],
    };
  },
  mounted() {
    this.getJobType();
  },
  methods: {
    async getJobType() {
      this.loading = true;
      try {
        const res = await AdminPurpleScribeService.getJobType(
          this.$route.params.id
        );
        this.jobType = res.data;
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not get Purple Scribe session type, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async updateJobType(jobType) {
      this.loading = true;

      try {
        const res = await AdminPurpleScribeService.updateJobType(
          this.$route.params.id,
          jobType
        );
        this.jobType = res.data;

        this.$toast.success("Session type was successfully updated.", {
          toastClassName: ["toast-std", "success-toast"],
        });

        this.closeModals();
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not update Purple Scribe session type, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteJobType() {
      this.loading = true;
      try {
        await AdminPurpleScribeService.deleteJobType(this.$route.params.id);
        this.$router.push({ name: "admin-purple-scribe-job-types" });
        this.$toast.success("Session type was successfully deleted.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not delete Purple Scribe session type, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    showDeleteModal() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this session type? This action cannot be undone.",
          {
            title: "Delete Session Type",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.deleteJobType();
          }
        });
    },
    closeModals() {
      this.$bvModal.hide("modal-update-scribe-job-type");
      this.$bvModal.hide("modal-add-summary-type");
      this.$bvModal.hide("modal-add-enterprise");
    },
    addSummaryType(selectedSummaryType) {
      let summaryTypeIds = this.jobType.summary_types.map(s => s.id);
      if (summaryTypeIds.includes(selectedSummaryType.id)) {
        this.$toast.error("The selected summary type already has access to this job type.", {
          toastClassName: ["toast-std", "warning-toast"],
        });
        return
      }
      summaryTypeIds.push(selectedSummaryType.id);
      const dto = {
        name: this.jobType.name,
        display_name: this.jobType.display_name,
        global: this.jobType.global,
        enterprise_uids: this.jobType.enterprises.map(e => e.uid),
        summary_type_ids: summaryTypeIds
      }
      this.updateJobType(dto);
    },
    showRemoveSummaryTypeModal(selectedId) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to remove this summary type?",
          {
            title: "Remove Summary Type",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Remove",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.removeSummaryType(selectedId);
          }
        });
    },
    removeSummaryType(selectedId) {
      const dto = {
        name: this.jobType.name,
        display_name: this.jobType.display_name,
        global: this.jobType.global,
        enterprise_uids: this.jobType.enterprises.map(e => e.uid),
        summary_type_ids: this.jobType.summary_types
          .map(s => s.id)
          .filter(id => id !== selectedId),
      };
      this.updateJobType(dto);
    },
    addEnterprise(selectedEnterprise) {
      let enterpriseUids = this.jobType.enterprises.map(e => e.uid);
      if (enterpriseUids.includes(selectedEnterprise.uid)) {
        this.$toast.error("The selected enterprise already has access to this job type.", {
          toastClassName: ["toast-std", "warning-toast"],
        });
        return
      }
      enterpriseUids.push(selectedEnterprise.uid);
      const dto = {
        name: this.jobType.name,
        display_name: this.jobType.display_name,
        global: this.jobType.global,
        enterprise_uids: enterpriseUids,
        summary_type_ids: this.jobType.summary_types.map(s => s.id)
      }
      this.updateJobType(dto);
    },
    showRemoveEnterpriseModal(selectedUid) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to remove this enterprise?",
          {
            title: "Remove Enterprise",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Remove",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.removeEnterprise(selectedUid);
          }
        });
    },
    removeEnterprise(selectedUid) {
      const dto = {
        name: this.jobType.name,
        display_name: this.jobType.display_name,
        global: this.jobType.global,
        enterprise_uids: this.jobType.enterprises.map(e => e.uid).filter(uid => uid !== selectedUid),
        summary_type_ids: this.jobType.summary_types.map(s => s.id)
      };
      this.updateJobType(dto);
    },
  },
};
</script>

<style lang="scss" scoped></style>
