<template>
  <section>
    <label>Summary Type</label>
    <scribe-summary-type-search-select
      :selected="selectedSummaryType"
      @change="updateSelectedSummaryType"
    />

    <section class="d-inline-flex full-width mt-4">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close')">Cancel</b-button>
      <b-button
        type="submit"
        variant="primary"
        :disabled="!selectedSummaryType"
        @click="$emit('update', selectedSummaryType)"
      >
        Update Session Type
      </b-button>
    </section>
  </section>
</template>

<script>
import ScribeSummaryTypeSearchSelect from "@/components/input/PaginatedSearchSelect/ScribeSummaryTypeSearchSelect.vue";

export default {
  name: "AddSummaryTypeModal",
  components: { ScribeSummaryTypeSearchSelect },
  data() {
    return {
      selectedSummaryType: null,
    }
  },
  methods: {
    updateSelectedSummaryType(summaryType) {
      this.selectedSummaryType = summaryType;
    }
  }
};
</script>

<style scoped lang="scss"></style>
